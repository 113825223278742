.btn {
  text-transform: uppercase;
  letter-spacing: 0px;
  padding: 10px;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}
.btn.btn-primary {
  background-color: #004bed;
  border-color: #004bed;
}
.btn.btn-primary:hover {
  background-color: #004bed;
  border-color: #004bed;
  opacity: 0.8;
}
.btn.btn-dark {
  background-color: #4a525a;
  border-color: #4a525a;
}
.btn.btn-dark:hover {
  background-color: #4a525a;
  border-color: #4a525a;
  opacity: 0.8;
}
.btn.btn-link {
  background-color: transparent !important;
  border-color: none !important;
}
.btn.btn-link:hover {
  background-color: none !important;
  border-color: none !important;
  opacity: 0.8;
}
.btn:hover {
  opacity: 0.8;
}
.btn.btn-block {
  display: block;
}
.btn i.fa,
.btn i.fas,
.btn i.fab,
.btn i.fal,
.btn i.far {
  padding: 0 10px;
}
.btn.btn-sm {
  padding: 5px;
}
.btn-lg-font {
  font-size: 24px;
}
.btn-icon {
  color: #fffefd;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}
.btn-icon:hover {
  color: #9b9b9b;
}
.btn-fb {
  background-color: #4267b2 !important;
  border-color: #4267b2 !important;
}
.btn-google {
  background-color: #4285f4 !important;
  border-color: #4285f4 !important;
}
.field-error {
  border: 2px solid #ff6565;
}
.row {
  width: 100% !important;
  margin: 0 !important;
}
form div .col:first-child,
form .row div:first-child {
  padding-left: 0 !important;
}
form div .col:last-child,
form .row div:last-child {
  padding-right: 0 !important;
}
div .one-third {
  width: calc(33.33% - 10px);
  margin-right: 15px;
}
div .one-third:nth-child(3n) {
  margin-right: 0;
}
@media screen and (max-width: 992px) {
  div .one-third {
    width: calc(50% - 7.5px);
    margin-right: 15px;
  }
  div .one-third:nth-child(3n) {
    margin-right: 15px;
  }
  div .one-third:nth-child(2n) {
    margin-right: 0;
  }
}
@media screen and (max-width: 600px) {
  div .one-third {
    width: 100%;
    margin-right: 0 !important;
  }
}
.form-control {
  height: 41px !important;
  margin-bottom: 0 !important;
}
.field,
.form-control {
  text-align: left;
  margin: 10px 0;
  margin-top: 0 !important;
}
.field label,
.form-control label {
  display: block;
  text-align: left;
  padding: 2px;
  font-weight: 400;
  font-size: 12px;
  color: #e7e7e7;
  margin: 0;
}
.field input,
.form-control input,
.field .react-tags,
.form-control .react-tags {
  display: block;
  width: 100%;
  border: solid 1px #4a525a;
  -webkit-appearance: none;
  border-radius: 4px;
  padding: 9px;
  color: #dbdee1;
  outline: none;
  background-color: #4a525a !important;
  background-color: #4a525a;
}
.field input:focus,
.form-control input:focus,
.field .react-tags:focus,
.form-control .react-tags:focus,
.field input.active,
.form-control input.active,
.field .react-tags.active,
.form-control .react-tags.active {
  border: solid 1px #9b9b9b;
  color: #dbdee1;
}
.field input,
.form-control input {
  height: 41px !important;
}
.field .react-tags,
.form-control .react-tags {
  position: relative;
  padding: 7px;
  font-size: 16px;
  line-height: 18px;
  cursor: text;
}
.field .react-tags__search,
.form-control .react-tags__search {
  display: inline-block;
  margin-top: 3px;
  max-width: 100%;
}
.field .react-tags__search input,
.form-control .react-tags__search input {
  height: auto !important;
  max-width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: inherit;
  line-height: inherit;
  border-radius: 0;
}
.field .react-tags__search input::-ms-clear,
.form-control .react-tags__search input::-ms-clear {
  display: none;
}
.field .react-tags__search-input input,
.form-control .react-tags__search-input input {
  width: auto !important;
}
@media screen and (min-width: 30em) {
  .field .react-tags__search,
  .form-control .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative;
  }
}
.field .react-tags__selected,
.form-control .react-tags__selected {
  display: inline;
}
.field .react-tags__selected-tag,
.form-control .react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 7px 7px 0;
  padding: 3px 8px;
  border-radius: 4px;
  background: #004bed;
  color: #fff;
  border: none;
  -webkit-appearance: none;
  font-size: inherit;
  line-height: inherit;
  opacity: 1;
  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity ease-in-out 0.3s;
  -ms-transition: opacity ease-in-out 0.3s;
  -o-transition: opacity ease-in-out 0.3s;
}
.field .react-tags__selected-tag:hover,
.form-control .react-tags__selected-tag:hover {
  opacity: 0.7;
}
.field .react-tags__selected-tag:after,
.form-control .react-tags__selected-tag:after {
  font-family: "Font Awesome 5 Pro";
  content: "\f00d";
  color: rgba(0, 0, 0, 0.75);
  font-size: 12px;
  margin-left: 8px;
}
.field .react-tags__suggestions,
.form-control .react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 999;
}
@media screen and (min-width: 30em) {
  .field .react-tags__suggestions,
  .form-control .react-tags__suggestions {
    width: 240px;
  }
}
.field .react-tags__suggestions ul,
.form-control .react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25);
}
.field .react-tags__suggestions li,
.form-control .react-tags__suggestions li {
  border-bottom: 0.5px solid #e7e7e7;
  padding: 10px 10px;
  color: #24272b;
  font-weight: lighter;
}
.field .react-tags__suggestions li mark,
.form-control .react-tags__suggestions li mark {
  background: none;
  font-weight: 600;
  color: #24272b;
}
.field .react-tags__suggestions li:first-child,
.form-control .react-tags__suggestions li:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.field .react-tags__suggestions li:last-child,
.form-control .react-tags__suggestions li:last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border: none;
}
.field .react-tags__suggestions li:hover,
.form-control .react-tags__suggestions li:hover {
  cursor: pointer;
  background: #f5f5f5;
}
.field .react-tags__suggestions li.is-active,
.form-control .react-tags__suggestions li.is-active {
  background: #004bed;
  color: #fffefd;
}
.field .react-tags__suggestions li.is-active mark,
.form-control .react-tags__suggestions li.is-active mark {
  color: #fffefd;
}
.field .react-tags__suggestions li.is-disabled,
.form-control .react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}
.field textarea,
.form-control textarea {
  width: 100%;
  height: 100%;
  border: solid 1px #4a525a;
  -webkit-appearance: none;
  border-radius: 4px;
  padding: 9px;
  color: #dbdee1;
  outline: none;
  background-color: #4a525a;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}
.field textarea:focus,
.form-control textarea:focus,
.field textarea.active,
.form-control textarea.active {
  border: solid 1px #9b9b9b;
  background-color: #4a525a;
  color: #dbdee1;
}
.field .react-select-container,
.form-control .react-select-container {
  border-radius: 4px;
  height: 41px !important;
}
.field .react-select-container > div:first-of-type,
.form-control .react-select-container > div:first-of-type {
  cursor: pointer;
  height: 41px;
  border-radius: 4px;
  border-color: #4a525a;
  color: #dbdee1;
  outline: none;
  background-color: #4a525a;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}
.field .react-select-container > div:first-of-type:focus,
.form-control .react-select-container > div:first-of-type:focus,
.field .react-select-container > div:first-of-type.active,
.form-control .react-select-container > div:first-of-type.active {
  border: solid 1px #9b9b9b;
  background-color: #4a525a;
  color: #dbdee1;
}
.field .react-select-container .react-select__value-container,
.form-control .react-select-container .react-select__value-container {
  height: 41px !important;
  padding-left: 5px !important;
}
.field .react-select-container .react-select__control--is-focused,
.form-control .react-select-container .react-select__control--is-focused {
  border-color: solid 1px #9b9b9b;
  box-shadow: 0 0 0 1px #9b9b9b;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}
.field .react-select-container .react-select__menu,
.form-control .react-select-container .react-select__menu {
  background-color: #4a525a;
  border-radius: 4px;
  z-index: 10000;
}
.field .react-select-container .react-select__menu-list,
.form-control .react-select-container .react-select__menu-list {
  padding: 0px;
}
.field .react-select-container .react-select__option,
.form-control .react-select-container .react-select__option {
  cursor: pointer;
  color: #dbdee1;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}
.field .react-select-container .react-select__option:focus,
.form-control .react-select-container .react-select__option:focus,
.field .react-select-container .react-select__option.active,
.form-control .react-select-container .react-select__option.active {
  border: #9b9b9b;
  color: #9b9b9b;
}
.field .react-select-container .react-select__option:hover,
.form-control .react-select-container .react-select__option:hover {
  color: #4a525a;
}
.field .react-select-container .react-select__option:first-child,
.form-control .react-select-container .react-select__option:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding-left: 12px !important;
}
.field .react-select-container .react-select__option:last-child,
.form-control .react-select-container .react-select__option:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.field .react-select-container .react-select__single-value,
.form-control .react-select-container .react-select__single-value {
  color: #dbdee1;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}
.field .react-select-container .react-select__option--is-focused,
.form-control .react-select-container .react-select__option--is-focused {
  color: #9b9b9b;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}
.field .react-select-container .react-select__option--is-focused:hover,
.form-control .react-select-container .react-select__option--is-focused:hover {
  border: #dbdee1;
}
.field .react-select-container .react-select__indicator-separator,
.form-control .react-select-container .react-select__indicator-separator {
  background-color: transparent;
}
.field .react-select-container .react-select__indicator,
.form-control .react-select-container .react-select__indicator {
  color: #9b9b9b;
}
.field .react-select-container .react-select__indicator:focus,
.form-control .react-select-container .react-select__indicator:focus,
.field .react-select-container .react-select__indicator:active,
.form-control .react-select-container .react-select__indicator:active,
.field .react-select-container .react-select__indicator:hover,
.form-control .react-select-container .react-select__indicator:hover,
.field .react-select-container .react-select__indicator:visited,
.form-control .react-select-container .react-select__indicator:visited {
  color: #dbdee1;
}
.field .datetime-picker .react-datepicker-wrapper,
.form-control .datetime-picker .react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  position: relative;
  box-sizing: border-box;
}
.field .datetime-picker .react-datepicker-wrapper::before,
.form-control .datetime-picker .react-datepicker-wrapper::before {
  left: 14px;
  top: 10px;
  z-index: 1;
  pointer-events: none;
  color: #9b9b9b;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  position: absolute;
}
.field .datetime-picker .react-datepicker-wrapper .react-datepicker__input-container,
.form-control .datetime-picker .react-datepicker-wrapper .react-datepicker__input-container {
  position: relative;
}
.field .datetime-picker .react-datepicker-wrapper .react-datepicker__input-container::after,
.form-control .datetime-picker .react-datepicker-wrapper .react-datepicker__input-container::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 40px;
  width: 1px;
  height: 100%;
  background-color: #4a525a;
  z-index: 9;
  display: inline-block;
  pointer-events: none;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}
.field .datetime-picker .react-datepicker-wrapper input,
.form-control .datetime-picker .react-datepicker-wrapper input {
  padding-left: 47px;
}
.field .datetime-picker.date-active .date-picker-container .react-datepicker-wrapper .react-datepicker__input-container::after,
.form-control .datetime-picker.date-active .date-picker-container .react-datepicker-wrapper .react-datepicker__input-container::after {
  background-color: #9b9b9b;
}
.field .datetime-picker.time-active .time-picker-container .react-datepicker-wrapper .react-datepicker__input-container::after,
.form-control .datetime-picker.time-active .time-picker-container .react-datepicker-wrapper .react-datepicker__input-container::after {
  background-color: #9b9b9b;
}
.field .datetime-picker .date-picker-container,
.form-control .datetime-picker .date-picker-container {
  display: inline-block;
  width: 100%;
}
.field .datetime-picker .date-picker-container .react-datepicker-wrapper,
.form-control .datetime-picker .date-picker-container .react-datepicker-wrapper {
  margin-right: 0;
}
.field .datetime-picker .date-picker-container .react-datepicker-wrapper::before,
.form-control .datetime-picker .date-picker-container .react-datepicker-wrapper::before {
  content: "\f783";
}
.field .datetime-picker .time-picker-container,
.form-control .datetime-picker .time-picker-container {
  display: inline-block;
  width: 50%;
}
.field .datetime-picker .time-picker-container .react-datepicker-wrapper,
.form-control .datetime-picker .time-picker-container .react-datepicker-wrapper {
  margin-left: 10px;
}
.field .datetime-picker .time-picker-container .react-datepicker-wrapper::before,
.form-control .datetime-picker .time-picker-container .react-datepicker-wrapper::before {
  content: "\f017";
}
.field .datetime-picker .react-datepicker-popper,
.form-control .datetime-picker .react-datepicker-popper {
  z-index: 10;
}
.field .description,
.form-control .description {
  display: inline-block;
  width: 100%;
  text-align: left;
  font-size: 12px;
}
.field .error,
.form-control .error {
  display: inline-block;
  width: 100%;
  text-align: left;
  color: #e71d36;
  font-size: 12px;
}
.field .description ~ .error,
.form-control .description ~ .error {
  width: 100%;
}
.field.inline,
.form-control.inline {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.field .MuiSkeleton-rect,
.form-control .MuiSkeleton-rect {
  height: 41px;
  border-radius: 4px;
}
.error {
  display: inline-block;
  width: 100%;
  text-align: left;
  color: #e71d36;
  font-size: 12px;
}
#radio-input-wrapper {
  /* Customize the label (the container) */
  /* Hide the browser's default radio button */
  /* Create a custom radio button */
  /* On mouse-over, add a grey background color */
  /* When the radio button is checked, add a blue background */
  /* Create the indicator (the dot/circle - hidden when not checked) */
  /* Show the indicator (dot/circle) when checked */
  /* Style the indicator (dot/circle) */
}
#radio-input-wrapper .container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-top: 12px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
#radio-input-wrapper .container p {
  font-size: 12px;
  margin-bottom: 0;
  font-weight: 500;
}
#radio-input-wrapper .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
#radio-input-wrapper .checkmark {
  position: absolute;
  top: 10px;
  left: 10px;
  height: 15px;
  width: 15px;
  background-color: #eee;
  border-radius: 50%;
}
#radio-input-wrapper .container:hover input ~ .checkmark {
  background-color: #ccc;
}
#radio-input-wrapper .container input:checked ~ .checkmark {
  background-color: #2196f3;
}
#radio-input-wrapper .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
#radio-input-wrapper .container input:checked ~ .checkmark:after {
  display: block;
}
#radio-input-wrapper .container .checkmark:after {
  top: 3px;
  left: 3px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: white;
}
.field-label-style {
  display: block !important;
  text-align: left !important;
  padding: 2px !important;
  font-weight: 400;
}
.toggle-field {
  margin: 10px 0;
}
.toggle-field .field {
  margin: 0;
}
.toggle-field .checkbox input[type="checkbox"] {
  margin-left: 0;
}
.margin-top-label {
  margin-top: 37px;
}
.checkbox > input {
  margin-right: 10px;
}
.form-uploader {
  padding: 40px;
  text-align: center;
  background-color: #e7e7e7;
  border-radius: 4px;
  position: relative;
  flex-grow: 1;
}
.form-uploader .form-options {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  pointer-events: none;
  transition: all ease-in-out 0.2s;
  z-index: 3;
  width: 100%;
}
.form-uploader .form-options > button {
  margin: 5px;
}
.form-uploader .form-uploader-coverall {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  z-index: 2;
  border-radius: 4px;
  transition: all ease-in-out 0.2s;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.75);
}
.form-uploader .form-uploader-value {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  z-index: 1;
  border-radius: 4px;
  transition: all ease-in-out 0.2s;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.75);
}
.form-uploader:hover .form-options {
  opacity: 1;
  pointer-events: all;
}
.form-uploader:hover .form-uploader-coverall {
  opacity: 1;
}
.password-field {
  position: relative;
}
.password-field .password-toggle {
  position: absolute;
  right: 10px;
  top: 20px;
}
.login-form .password-field {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: all ease-in-out 0.5s;
  -moz-transition: all ease-in-out 0.5s;
  -ms-transition: all ease-in-out 0.5s;
  -o-transition: all ease-in-out 0.5s;
}
.login-form.active .password-field {
  max-height: 151px;
}
.summary-box {
  background: #24272b;
  border-radius: 4px;
  margin-bottom: 10px;
  overflow: hidden;
}
.summary-box .summary-title-wrapper {
  padding: 20px 20px;
  background-color: #e71d36;
  display: flex;
  align-items: center;
}
.summary-box .summary-title-wrapper h3 {
  margin: 0;
  margin-bottom: 2px;
  color: #fffefd;
  width: 100%;
  text-align: left;
}
.summary-box .summary-title-wrapper i {
  margin-right: 5px;
  color: #fffefd;
}
.summary-box ul {
  padding: 20px;
  margin-bottom: 10px;
}
.summary-box ul li {
  list-style-type: none;
  padding-top: 10px;
}
.summary-box li,
.summary-box a {
  color: #fffefd;
}
.summary-box li:hover,
.summary-box a:hover {
  text-decoration: underline;
  color: #fffefd;
}
.react-select-container {
  height: 41px !important;
}
.react-select-container > div:first-of-type {
  height: 41px;
  border-radius: 4px;
  border-color: #4a525a;
}
.react-select-container .react-select__option {
  color: #24272b;
}
.checkbox-row {
  padding: 20px;
  border-radius: 40px;
  color: #24272b;
  font-size: 20px;
  line-height: 30px;
  background: #fffefd;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}
.checkbox-row input {
  margin: 0 10px 0 0 !important;
  border-radius: 30px;
  -webkit-appearance: none;
  outline: none !important;
  width: 30px;
  height: 30px;
  border: solid #24272b 3px;
  vertical-align: middle;
  position: relative;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}
.checkbox-row input::before {
  content: "";
  width: 3px;
  height: 0px;
  background: #24272b;
  display: inline-block;
  position: absolute;
  top: 12px;
  left: 6px;
  transform-origin: top left;
  transform: rotate(-45deg);
  -webkit-transition: all ease-in-out 0.1s;
  -moz-transition: all ease-in-out 0.1s;
  -ms-transition: all ease-in-out 0.1s;
  -o-transition: all ease-in-out 0.1s;
  transition-delay: 0.2s;
}
.checkbox-row input::after {
  content: "";
  width: 3px;
  height: 0px;
  background: #24272b;
  display: inline-block;
  position: absolute;
  top: 14px;
  left: 6px;
  transform-origin: top right;
  transform: rotate(-135deg);
  transition-delay: 0s;
  -webkit-transition: all ease-in-out 0.2s;
  -moz-transition: all ease-in-out 0.2s;
  -ms-transition: all ease-in-out 0.2s;
  -o-transition: all ease-in-out 0.2s;
}
.checkbox-row input:checked::before {
  transition-delay: 0s;
  height: 6px;
}
.checkbox-row input:checked::after {
  transition-delay: 0.1s;
  height: 12px;
}
.checkbox-row input.active {
  background: #004bed;
  color: #fffefd;
}
.checkbox-row.active {
  background: #004bed;
  color: #fffefd;
}
.checkbox-row.active input {
  border: solid #fffefd 3px;
}
.checkbox-row.active input::before {
  background: #fffefd;
}
.checkbox-row.active input::after {
  background: #fffefd;
}
.upload-wrap {
  background-color: #4a525a;
  padding: 9px;
  border-radius: 4px;
}
.upload-wrap i {
  color: #fffefd;
}
.upload-wrap .placeholder {
  left: 10px;
  bottom: 100%;
  padding: 0 0 10px 0;
  transform: scale(0.7);
}
.upload-wrap .empty-upload {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: dashed 1px #24272b;
}
.upload-wrap .upload-input {
  font-weight: 300;
  border: none;
  -webkit-appearance: none;
  text-align: left;
  padding: 4px 10px;
  display: block;
  width: 100%;
  outline: none;
  position: relative;
  height: 18px;
  line-height: 9px;
  font-size: 9px;
  overflow-x: auto;
  cursor: pointer;
  word-break: break-all;
}
.upload-wrap .upload-progress-wrap {
  display: inline-block;
  width: 100%;
  height: 4px;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 9;
  text-align: left;
}
.upload-wrap .upload-progress-wrap .upload-progress-bar {
  height: 4px;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
}
.upload-wrap .upload-image-preview {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: block;
  height: 69px;
  overflow: hidden;
  border-radius: 4px;
  -webkit-transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -moz-transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -ms-transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -o-transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.upload-wrap .upload-image-preview .upload-icon {
  font-size: 28px;
  line-height: 28px;
  text-align: right;
  padding-right: 3px;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  color: #24272b;
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0.5;
  transform: translate(-50%, -50%);
}
.upload-wrap .upload-image-preview.on .upload-icon {
  opacity: 0;
  pointer-events: none;
}
.upload-wrap .upload-image-preview:not(.on) {
  cursor: pointer;
}
.upload-wrap .upload-image-preview .image-remove {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  text-align: center;
  padding: 10px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 40px;
  cursor: pointer;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}
.upload-wrap .upload-image-preview .image-remove i {
  font-size: 20px;
  color: #fffefd;
  line-height: 20px;
}
.upload-wrap .upload-image-preview.on:hover .image-remove {
  opacity: 1;
}
.upload-errors {
  box-shadow: #e71d36 0px 0px 2px 1px, #e71d36 0px 0px 0px 3px;
  border: 1px solid #e71d36 !important;
  background-color: rgba(231, 29, 54, 0.1) !important;
}
.fa5-icon-picker {
  width: 100%;
  margin-top: -4px;
}
.fa5-icon-picker .icon-picker-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.fa5-icon-picker .icon-picker-row .select-container {
  width: 100%;
  margin: 0px 10px;
}
@media only screen and (max-width : 575px) {
  .fa5-icon-picker .icon-picker-row .select-container:first-child {
    margin-left: 0;
  }
}
.fa5-icon-picker .icon-picker-row .select-container .react-select__control {
  height: 41px;
}
.fa5-icon-picker .icon-picker-row .select-container .react-select__value-container {
  height: 41px;
  margin-left: 5px;
}
.fa5-icon-picker .icon-picker-row .select-container .react-autosuggest__suggestions-container--open {
  position: absolute;
  height: 200px;
  width: 80%;
  overflow-y: scroll;
  z-index: 999;
  background-color: #4a525a;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 4px;
}
.fa5-icon-picker .icon-picker-row .select-container .react-autosuggest__suggestions-container--open .react-autosuggest__suggestion {
  list-style-type: none;
  margin-left: -40px;
  padding-left: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
}
.fa5-icon-picker .icon-picker-row .select-container .react-autosuggest__suggestions-container--open .react-autosuggest__suggestion:hover {
  cursor: pointer;
  background-color: #3e454c;
}
.fa5-icon-picker .icon-picker-row .select-container .react-autosuggest__suggestions-container--open .react-autosuggest__suggestion:hover .select-box {
  position: relative;
}
.fa5-icon-picker .icon-picker-row .select-container .react-autosuggest__suggestions-container--open .react-autosuggest__suggestion:hover .select-box label {
  position: absolute;
  top: 0;
  font-size: 12px;
}
.fa5-icon-picker .icon-picker-row .preview-box div {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.file-picker-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  max-width: 600px;
  max-height: 400px;
  transform: translate(-50%, -50%);
  z-index: 1000001;
  border-radius: 4px;
  overflow: hidden;
  text-align: left;
  -webkit-transition: opacity ease-in-out 0.3s;
  -moz-transition: opacity ease-in-out 0.3s;
  -ms-transition: opacity ease-in-out 0.3s;
  -o-transition: opacity ease-in-out 0.3s;
}
.file-picker-modal.off {
  opacity: 0;
  pointer-events: none;
}
.file-picker-modal.on {
  opacity: 1;
  pointer-events: all;
}
.file-picker-modal .file-manager-window {
  width: 100%;
  height: 100%;
  position: relative;
}
.file-picker-modal .file-manager-window .file-list {
  display: inline-block;
  vertical-align: top;
  width: 70%;
  text-align: left;
  border-right: 1px solid #4a525a;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  background-color: #393e45;
}
.file-picker-modal .file-manager-window .file-list > :nth-child(odd) {
  background: #4a525a;
}
.file-picker-modal .file-manager-window .file-list > div {
  padding: 4px;
  cursor: pointer;
  background-color: #393e45;
}
.file-picker-modal .file-manager-window .file-list > div.on {
  background-color: #24272b;
  color: #fffefd;
}
.file-picker-modal .file-manager-window .file-confirmation {
  display: inline-block;
  vertical-align: top;
  width: calc(30% - 1px);
  height: 100%;
  background-color: #24272b;
  color: #fffefd;
  padding: 10px;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 46px;
}
.file-picker-modal .file-manager-window .file-confirmation .file-confirmation-preview > img {
  width: 100%;
}
.file-picker-modal .file-manager-window .file-confirmation .select-file {
  position: fixed;
  bottom: 10px;
  right: 11px;
  width: calc(30% - 21px);
}
fieldset:disabled input,
fieldset:disabled textarea,
fieldset:disabled .form-control,
fieldset:disabled .react-select__control {
  background-color: #4a525a;
  opacity: 0.5;
}
.form-button-spinner {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}
.form-section-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
}
.form-section-title h1,
.form-section-title input,
.form-section-title label {
  margin: 0;
}
/* Customize the label (the container) */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-top: 10px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox-container.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.form-toggle .checkbox-container {
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 10px;
}
.form-toggle .checkbox-container span {
  font-size: 16px;
}
/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.checkbox-checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 2px;
}
/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkbox-checkmark {
  background-color: #fff;
}
/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkbox-checkmark {
  background-color: #004bed;
  border: none;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkbox-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkbox-checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.checkbox-container .checkbox-checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.darkmode-field label {
  font-size: 12px;
}
.darkmode-field .tox {
  background-color: #4a525a !important;
}
.darkmode-field .tox-tinymce {
  border-radius: 5px !important;
  overflow: hidden;
  border: none !important;
}
.darkmode-field .tox-editor-header {
  border-bottom: #fffefd !important;
}
.darkmode-field .tox-toolbar__primary {
  background: none !important;
  background-color: #4a525a !important;
  border-bottom: 1px solid #798591 !important;
}
.darkmode-field .tox-edit-area {
  background-color: #4a525a !important;
}
.darkmode-field .tox-sidebar-wrap {
  background-color: #4a525a !important;
}
.darkmode-field .tox-edit-area__iframe {
  background-color: #4a525a !important;
}
.darkmode-field body {
  background: transparent;
  color: yellow;
}
.tox .tox-dialog__header,
.tox .tox-dialog,
.tox .tox-dialog__footer {
  background-color: #24272b !important;
}
.tox .tox-dialog {
  border: none !important;
  padding: 10px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.15) !important;
  border-radius: 8px !important;
}
.tox .tox-textarea {
  background-color: #4a525a !important;
  border-radius: 8px !important;
  border: none !important;
}
.tox .tox-dialog__footer {
  border-top: none !important;
}
.tox .tox-button {
  text-transform: uppercase !important;
  background-color: #004bed !important;
  border: none !important;
}
.tox .tox-button:hover {
  opacity: 0.8;
}
.tox .tox-button--secondary {
  background-color: transparent !important;
}
.tox .tox-button--icon {
  background-color: transparent !important;
}
.channel-checkbox {
  width: 50%;
  display: inline-block;
  padding-right: 10px;
  vertical-align: top;
}
@media only screen and (max-width : 768px) {
  .channel-checkbox {
    width: 100%;
  }
}
.light-form .field input {
  background-color: #fffefd !important;
  color: #24272b !important;
}
.light-form .field label {
  color: #24272b !important;
}
.light-form button {
  color: #fffefd !important;
}
.coverall {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.75);
  top: 0;
  left: 0;
  z-index: 1000;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}
.coverall.active {
  opacity: 1;
  pointer-events: all;
}
.horizontal-rule {
  position: relative;
  text-align: center;
}
.horizontal-rule::before {
  content: "";
  width: 100%;
  height: 2px;
  display: inline-block;
  background-color: #4a525a;
  top: 50%;
  left: 0;
  position: absolute;
  z-index: 0;
}
.horizontal-rule .horizontal-rule-label {
  position: relative;
  z-index: 1;
  background-color: #24272b;
  display: inline-block;
  padding: 20px 15px;
}
.area-reveal {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: all ease-in-out 0.5s;
  -moz-transition: all ease-in-out 0.5s;
  -ms-transition: all ease-in-out 0.5s;
  -o-transition: all ease-in-out 0.5s;
}
.area-reveal.active {
  max-height: 1200px;
}
.area-reveal.active.md {
  max-height: 400px;
}
.modal-body > .chat-login {
  padding-right: 0 !important;
}
.modal-content .close {
  cursor: pointer;
}
.direct-chat-tabs {
  overflow-y: hidden;
  overflow-x: scroll;
  width: 100%;
  white-space: nowrap;
  padding-right: 50px;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.direct-chat-tabs::after {
  z-index: 2;
}
.direct-chat-tabs .direct-chat-tab {
  display: inline-block;
  height: 50px;
  width: 50px;
  vertical-align: top;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}
.direct-chat-tabs .direct-chat-tab::before {
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}
.direct-chat-tabs .direct-chat-tab:not(.active) {
  cursor: pointer;
}
.direct-chat-tabs .direct-chat-tab .chat-avatar {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  margin: 5px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}
.direct-chat-tabs .direct-chat-tab .chat-avatar > img {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 0;
  left: 0;
}
.direct-chat-tabs .direct-chat-tab.unreadChats .chat-avatar {
  border: solid 3px #f44c3f;
}
.direct-chat-tabs .direct-chat-tab.unreadChats .chat-avatar > img {
  top: -3px;
  left: -3px;
}
.user-pill {
  background-color: #fffefd;
  color: #24272b;
  padding: 0px 2px;
  border-radius: 2px;
  margin-left: 5px;
  font-size: 7px;
  text-transform: uppercase;
  vertical-align: middle;
  transform: translate(-2px, 0);
}
.user-pill > i {
  font-size: 0.75em;
}
h2 {
  font-size: 20px;
}
h3 {
  font-size: 16px;
}
.h-30 {
  height: 30px;
}
@media only screen and (max-width : 992px) {
  .h-30.flatten-mobile {
    height: 0;
    margin: 0;
  }
}
.h-50px {
  height: 50px;
}
@media only screen and (max-width : 992px) {
  .h-50px.flatten-mobile {
    height: 0;
    margin: 0;
  }
}
.white {
  color: #fffefd;
}
.black {
  color: #24272b;
}
.fw-400 {
  font-weight: 400;
}
.show-mobile {
  display: none !important;
}
@media only screen and (max-width : 992px) {
  .show-mobile {
    display: unset !important;
  }
}
@media only screen and (max-width : 992px) {
  .hide-mobile {
    display: none !important;
  }
}
header {
  height: 60px;
  z-index: 1000;
  background-color: #24272b;
  border-bottom: solid 2px #2d2f32;
  color: #fffefd;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
}
header > div.row {
  padding: 10px;
}
header .logo {
  font-size: 24px;
}
header .logo span {
  opacity: 0.6;
}
header .logo img {
  height: 24px;
  margin-left: 5px;
}
header .user-photo {
  width: 40px;
  height: 40px;
  background: #004bed;
  display: inline-block;
  float: right;
  border-radius: 40px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition-delay: 0.1s;
  cursor: pointer;
}
@media only screen and (max-width : 992px) {
  header .user-photo {
    position: fixed;
    left: 10px;
    bottom: -50px;
    z-index: 1000;
  }
}
header .user-menu {
  position: fixed;
  top: 60px;
  right: 0;
  background-color: #fffefd;
  width: 100%;
  max-width: 250px;
  z-index: 1000;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition-delay: 0.2s;
  opacity: 0;
  pointer-events: none;
  color: #24272b;
}
@media only screen and (max-width : 992px) {
  header .user-menu {
    top: unset;
    bottom: -50px;
    background-color: unset;
    color: #fffefd;
    opacity: 1;
    pointer-events: all;
  }
}
header .user-menu.active {
  opacity: 1;
  pointer-events: all;
}
header .user-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
header .user-menu ul li {
  padding: 10px;
  cursor: pointer;
}
header .hamburger {
  display: none;
  padding: 10px 0;
  cursor: pointer;
}
@media only screen and (max-width : 992px) {
  header .hamburger {
    display: inline-block;
  }
}
header .page-header {
  margin-left: 90px;
  color: #fffefd;
  font-size: 18px;
  line-height: 21px;
  padding: 7px 0;
  letter-spacing: 1.13px;
  -webkit-transition: margin-left ease-in-out 0.3s;
  -moz-transition: margin-left ease-in-out 0.3s;
  -ms-transition: margin-left ease-in-out 0.3s;
  -o-transition: margin-left ease-in-out 0.3s;
  transition-delay: 0.1s;
}
header .page-header .dashboard-header-subtitle {
  font-size: 12px;
  margin: 0px;
}
header .content-page-header {
  margin-top: -8px;
}
header.expanded .page-header {
  margin-left: 190px;
  transition-delay: 0s;
}
header .org-title {
  display: inline-block;
  width: calc(100% - 50px);
  margin-right: 10px;
  color: #fffefd;
  font-size: 18px;
  line-height: 21px;
  margin-top: 8px;
  letter-spacing: 1.13px;
}
header .breadcrumbs {
  background-color: #393e45;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
}
@media only screen and (max-width : 992px) {
  header .breadcrumbs {
    background-color: #24272b;
    position: fixed;
    top: 60px;
    right: -100vw;
    width: 100vw;
    height: calc(100vh -  60px );
    z-index: 999;
    flex-direction: column;
    justify-content: flex-start;
    -webkit-transition: all ease-in-out 0.4s;
    -moz-transition: all ease-in-out 0.4s;
    -ms-transition: all ease-in-out 0.4s;
    -o-transition: all ease-in-out 0.4s;
  }
  header .breadcrumbs .breadcrumb {
    background-color: transparent !important;
  }
  header .breadcrumbs .icon-wrapper {
    float: right;
  }
  header .breadcrumbs .breadcrumb-divider {
    display: none;
  }
  header .breadcrumbs.active {
    right: 0;
  }
}
header .breadcrumbs .breadcrumb-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
@media only screen and (max-width : 992px) {
  header .breadcrumbs .breadcrumb-wrapper {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }
}
header .breadcrumbs .breadcrumb {
  margin: 0;
  background-color: #393e45;
  border-radius: 0;
  display: inline-block;
  margin-right: 20px;
  padding: 0;
  color: white;
  transition: all 0.25s;
}
header .breadcrumbs .breadcrumb h3,
header .breadcrumbs .breadcrumb h5 {
  display: block;
  text-align: left;
  padding: 0;
  margin: 0;
  font-weight: normal;
}
header .breadcrumbs .breadcrumb h3 {
  font-size: 18px;
}
header .breadcrumbs .breadcrumb h5 {
  font-size: 12px;
  text-transform: uppercase;
}
header .breadcrumbs .breadcrumb:hover {
  opacity: 0.5;
  text-decoration: none;
}
header .breadcrumbs .breadcrumb-divider {
  font-size: 26px;
  margin-right: 20px;
}
header .breadcrumbs .icon-wrapper {
  display: flex;
  flex-direction: column;
}
header .breadcrumbs .icon-wrapper a {
  margin-right: 20px;
  color: white;
  transition: all 0.25s;
}
header .breadcrumbs .icon-wrapper a:hover {
  opacity: 0.5;
}
@media only screen and (max-width : 992px) {
  header .breadcrumbs .icon-wrapper {
    display: block;
  }
  header .breadcrumbs .icon-wrapper a {
    margin-right: 0;
    margin-left: 4px;
    padding: 10px;
    background-color: #393e45;
    border-radius: 4px;
  }
}
header .breadcrumbs .settings {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
@media only screen and (max-width : 992px) {
  header .breadcrumbs .settings {
    align-self: start;
    flex: unset;
    position: fixed;
    right: 10px;
    bottom: -50px;
    -webkit-transition: all ease-in-out 0.3s;
    -moz-transition: all ease-in-out 0.3s;
    -ms-transition: all ease-in-out 0.3s;
    -o-transition: all ease-in-out 0.3s;
    transition-delay: 0.2s;
  }
}
header .breadcrumbs .settings a {
  color: white;
  font-size: 24px;
  transition: all 0.25s;
}
header .breadcrumbs .settings a:hover {
  opacity: 0.5;
  text-decoration: none;
}
@media only screen and (max-width : 992px) {
  header.active .user-photo {
    bottom: 10px;
  }
}
@media only screen and (max-width : 992px) {
  header.active .settings {
    bottom: 10px;
  }
}
aside {
  background-color: #24272b;
  width: 80px;
  height: 100vh;
  padding: 10px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition-delay: 0.1s;
  border-right: solid 2px #2d2f32;
}
aside .logo-wrapper {
  height: 60px;
}
aside .logo-wrapper .logo {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  margin: 0;
  overflow: hidden;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition-delay: 0s;
}
aside .logo-wrapper .logo.churchx {
  margin-left: 0px;
}
aside .logo-wrapper .logo img {
  width: 90px;
}
aside .logo-wrapper .logo-badge {
  display: inline-block;
  vertical-align: middle;
  width: 50px;
  height: 36px;
  margin: 4px;
  text-align: center;
}
aside .logo-wrapper .logo-badge img {
  height: 100%;
}
aside .side-menu-button {
  color: #fffefd !important;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 4px;
  display: inline-block;
  width: 100%;
  max-width: 60px;
  padding: 10px;
  cursor: pointer;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}
@media only screen and (max-width : 992px) {
  aside .side-menu-button {
    max-width: 100%;
  }
}
aside .side-menu-button:hover {
  background-color: #393e45;
}
aside .side-menu-button.active {
  background-color: #004bed;
}
aside .side-menu-button .side-menu-icon {
  font-size: 26px;
  display: inline-block;
  vertical-align: middle;
  width: 40px;
  text-align: center;
  float: left;
}
aside .side-menu-button .side-menu-text {
  font-size: 16px;
  line-height: 19px;
  display: inline-block;
  vertical-align: middle;
  font-weight: 300;
  max-width: 0;
  overflow: hidden;
  text-transform: uppercase;
  letter-spacing: 1.25px;
  padding-left: 0px;
  padding-top: 8px;
  width: 100%;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}
@media only screen and (max-width : 992px) {
  aside .side-menu-button .side-menu-text {
    max-width: calc(100% - 40px);
    padding-left: 10px;
  }
}
aside .side-menu-button.expanded {
  max-width: 100%;
  transition-delay: 0.1s;
}
aside .side-menu-button.expanded .side-menu-text {
  padding-left: 10px;
  max-width: calc(100% - 40px);
  transition-delay: 0.1s;
}
aside .top {
  height: calc(100% - 120px);
  overflow-y: auto;
  overflow-x: hidden;
}
aside .top.admin {
  height: calc(100% - 178px);
}
aside .user-photo {
  width: 40px;
  height: 40px;
  background: #004bed;
  display: inline-block;
  border-radius: 40px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition-delay: 0.1s;
  cursor: pointer;
  margin: 10px 10px 0 10px;
  border: #fffefd solid 2px;
}
aside.expanded {
  width: 180px;
  transition-delay: 0s;
}
aside.expanded .logo {
  width: 90px;
  transition-delay: 0.1s;
}
aside.expanded .logo.churchx {
  margin-left: 10px;
}
@media only screen and (max-width : 992px) {
  aside {
    width: 100vw !important;
    border-right: none;
    height: calc(100vh -  60px );
    top: 60px;
    left: -100vw;
    z-index: 10001;
  }
  aside.open {
    left: 0;
  }
  aside .logo-wrapper {
    height: 0;
  }
  aside .top {
    height: calc(100% - 64px);
  }
  aside .top.admin {
    height: calc(100% - 128px);
  }
  aside .bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  aside .side-menu-mobile-footer {
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    border-top: solid 2px #2d2f32;
    margin-left: -10px;
    margin-right: -10px;
    padding: 10px 10px 0 10px;
  }
}
.top-subnav {
  display: none;
  background-color: #24272b;
  margin-top: 60px;
  padding: 10px;
  color: #fffefd;
}
.top-subnav.expanded {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
}
.top-subnav h3 {
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 1.13px;
  font-weight: 500;
}
.top-subnav h4 {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.88px;
  font-weight: 500;
}
.top-subnav .dots {
  float: right;
  margin-right: 10px;
  margin-top: 12px;
  background-color: #393e45;
  text-align: center;
  padding: 5px 0;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  cursor: pointer;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}
.top-subnav .dots:hover {
  background-color: #4a525a;
}
@media only screen and (max-width : 992px) {
  .top-subnav {
    display: block;
    width: 100%;
    z-index: 2;
    position: fixed;
  }
}
.top-nav-shim.active {
  height: 133px;
}
.navigator-section {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin-left: 80px;
  left: 0;
  padding: 70px 10px 10px 10px;
  width: 160px;
  position: fixed;
  top: 0;
  background-color: #24272b;
  color: #fffefd;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition-delay: 0.1s;
  z-index: 100;
}
.navigator-section nav {
  flex-grow: 10;
  overflow-y: scroll;
}
.navigator-section .navigator-footer {
  border-top: solid 2px #2d2f32;
  padding-top: 10px;
}
.navigator-section.expanded {
  left: 100px;
  transition-delay: 0s;
}
.navigator-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.navigator-section ul li {
  color: #fffefd;
  font-size: 12px;
  padding: 10px;
  border-radius: 6px;
  background-color: #24272b;
  font-weight: 500;
  letter-spacing: 0.75px;
  -webkit-transition: background-color ease-in-out 0.3s;
  -moz-transition: background-color ease-in-out 0.3s;
  -ms-transition: background-color ease-in-out 0.3s;
  -o-transition: background-color ease-in-out 0.3s;
  cursor: pointer;
}
.navigator-section ul li:hover {
  background-color: #393e45;
}
.navigator-section ul li.active {
  background-color: #4a525a;
}
.navigator-section ul li a {
  color: #fffefd !important;
  display: block;
  text-decoration: none;
}
@media only screen and (max-width : 992px) {
  .navigator-section {
    position: fixed;
    z-index: 10000;
    left: -100vw !important;
    top: 133px;
    height: calc(100vh -  133px );
    padding-top: 10px;
    margin-left: 0;
    border-top: solid 2px #2d2f32;
    width: 100%;
  }
  .navigator-section.active {
    left: 0 !important;
  }
}
main {
  text-align: center;
  padding: 60px 20px 20px 100px;
  -webkit-transition: margin-left ease-in-out 0.3s;
  -moz-transition: margin-left ease-in-out 0.3s;
  -ms-transition: margin-left ease-in-out 0.3s;
  -o-transition: margin-left ease-in-out 0.3s;
  transition-delay: 0.1s;
}
@media only screen and (max-width : 992px) {
  main {
    padding: 60px 10px 10px 10px;
  }
}
main.sm {
  max-width: 500px;
}
main.md {
  max-width: 800px;
}
main.lg {
  max-width: 1200px;
}
main.full {
  max-width: none;
  padding: 70px 20px 20px 100px;
  overflow: hidden;
  overflow-y: auto;
  overflow-x: hidden;
}
@media only screen and (max-width : 992px) {
  main.full {
    padding: 70px 0px 15px 0px;
    margin-left: 15px;
    margin-right: 15px;
  }
}
main.expanded {
  margin-left: 100px;
  transition-delay: 0s;
}
@media only screen and (max-width : 992px) {
  main.expanded {
    margin-left: 0;
    padding-left: 10px;
    padding-right: 10px;
  }
}
main.expanded.full {
  margin-left: 100px;
}
@media only screen and (max-width : 992px) {
  main.expanded.full {
    margin-left: 0px;
  }
}
main.has-sub-menu {
  margin: 0;
  margin-left: 160px;
}
main.has-sub-menu.expanded {
  margin-left: 300px;
}
@media only screen and (max-width : 992px) {
  main.has-sub-menu.expanded {
    margin-left: 0;
  }
}
main.has-sub-menu.expanded.full {
  margin-left: 245px;
}
@media only screen and (max-width : 992px) {
  main.has-sub-menu.expanded.full {
    margin-left: 15px;
  }
}
@media only screen and (max-width : 992px) {
  main.has-sub-menu {
    margin-left: 0;
    padding-top: 10px;
  }
}
main.has-sub-menu.full {
  margin-left: 145px;
}
@media only screen and (max-width : 992px) {
  main.has-sub-menu.full {
    margin-left: 15px;
  }
}
main.no-sidebar {
  margin: auto;
  padding-left: 10px;
}
main.centered {
  margin: auto;
}
@media only screen and (max-width : 992px) {
  .page-container {
    width: 100%;
    margin-left: 0px !important;
    padding-top: 40px !important;
  }
  .page-container.has-sub-menu {
    padding-top: 155px !important;
  }
  .page-container .host-panel {
    left: 0% !important;
    width: 100% !important;
  }
  .page-container .host-panel .host-chat-section {
    flex: none !important;
    max-width: 100% !important;
  }
  .page-container .host-panel .host-chat-section .show-mobile h3 {
    display: none;
  }
  .page-container .host-panel .host-chat-moderator-section {
    display: flex;
    width: 100%;
    position: fixed;
    /* flex: none; */
    max-width: none;
    background: black;
    height: calc(100% - 60px);
    z-index: 99;
    top: 60px;
    left: 0;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: opacity ease-in-out 0.3s;
    -moz-transition: opacity ease-in-out 0.3s;
    -ms-transition: opacity ease-in-out 0.3s;
    -o-transition: opacity ease-in-out 0.3s;
  }
  .page-container .host-panel .host-chat-moderator-section.active {
    opacity: 1;
    pointer-events: all;
  }
}
.dashboard-height {
  max-height: calc(100vh - 70px);
}
.dashboard-height-with-title {
  height: calc(100vh - 142px);
  position: relative;
}
.dashboard-page {
  position: relative;
  height: 100vh;
}
.dashboard-page .dashboard-sidebar {
  position: absolute;
  left: 80px;
  top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #24272b;
  width: 160px;
  height: calc(100vh - 60px);
  float: left;
  color: #fffefd;
}
.dashboard-page .dashboard-sidebar button {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  border: none;
  font-size: 14px;
  color: #fffefd;
  padding: 5px 15px;
  height: 26px;
  margin-bottom: 10px;
  border-radius: 4px;
}
.dashboard-page .dashboard-sidebar button i {
  width: 15px;
}
.dashboard-page .dashboard-sidebar button p {
  margin: 0;
  padding-left: 10px;
}
.dashboard-page .dashboard-sidebar button:hover,
.dashboard-page .dashboard-sidebar button.active {
  background-color: #393e45;
}
.dashboard-page .dashboard-sidebar .sidebar-top {
  padding-top: 15px;
  margin: 10px;
}
.dashboard-page .dashboard-sidebar .sidebar-top button p {
  padding-left: 0px;
}
.dashboard-page .dashboard-sidebar .sidebar-bottom {
  padding-top: 15px;
  padding-bottom: 15px;
  margin: 10px;
  border-top: 1px solid #2d2f32;
}
@media only screen and (max-width : 992px) {
  .dashboard-page .dashboard-sidebar {
    display: none;
  }
}
.dashboard-page .dashboard-right {
  width: calc(100% - 140px);
  float: right;
}
.dashboard-page .dashboard-right.analytics {
  padding-left: 20px;
}
@media only screen and (max-width : 992px) {
  .dashboard-page .dashboard-right {
    width: inherit;
    float: none;
  }
}
@media only screen and (max-width : 992px) {
  .host-panel-page {
    margin-top: 40px;
  }
}
@media only screen and (max-width : 992px) {
  .host-panel-page .dashboard-height-with-title {
    height: calc(100vh - 165px);
  }
}
.dashboard-height-half-with-title {
  height: calc(50vh - 40px);
  overflow-y: auto;
}
.well {
  background-color: #24272b;
  color: #fffefd;
  margin: 10px 0;
  border-radius: 8px;
  text-align: left;
}
.well span a {
  color: #fffefd;
  font-weight: 700;
}
.well p {
  margin: 0;
}
.well h3 {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 500;
  margin-top: 25px;
  margin-bottom: 0;
}
.well h3:first-child {
  margin-top: 0px;
}
.well .well-content {
  padding: 20px 24px;
}
.well .well-header {
  border-bottom: 1px solid #4a525a;
  padding: 20px 24px;
  width: 100%;
}
.well.sm-padding .well-content {
  padding: 20px 24px;
}
.well.p-0 .well-content {
  padding: 0;
}
.form-section {
  display: none;
}
.form-section-enter {
  display: block;
  opacity: 0;
  transition: opacity 200ms;
}
.form-section-enter-done {
  display: block;
}
.form-section-enter-active {
  display: block;
  opacity: 1;
  transition: opacity 200ms;
}
.form-section-exit {
  display: block;
  opacity: 1;
  transition: opacity 200ms;
}
.form-section-exit-active {
  display: block;
  opacity: 0;
  transition: opacity 200ms;
}
.page-title {
  color: #fffefd;
  font-size: 32px;
  letter-spacing: 0px;
  line-height: 39px;
  text-align: left;
  margin-top: 24px;
  margin-bottom: 8px;
  text-transform: uppercase;
}
.event-header-image {
  width: 100%;
  height: 120px;
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
  border-radius: 3px;
}
.event-header-image > img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  z-index: 1000;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
.event-header-image .screen-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  top: 0;
  left: 0;
  opacity: 0;
  -webkit-transition: opacity ease-in-out 0.3s;
  -moz-transition: opacity ease-in-out 0.3s;
  -ms-transition: opacity ease-in-out 0.3s;
  -o-transition: opacity ease-in-out 0.3s;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.75);
}
.event-header-image.show {
  overflow: visible;
}
.event-header-image.show .screen-overlay {
  opacity: 1;
}
.event-header-image .img-preview-btn {
  background: #24272b;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  color: #fffefd;
  padding: 10px;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  opacity: 0;
  z-index: 1001;
  -webkit-transition: opacity ease-in-out 0.3s;
  -moz-transition: opacity ease-in-out 0.3s;
  -ms-transition: opacity ease-in-out 0.3s;
  -o-transition: opacity ease-in-out 0.3s;
}
.event-header-image:hover .img-preview-btn {
  opacity: 1;
}
.event-title:not(.field) {
  font-weight: bold !important;
  font-size: 32px !important;
  margin-bottom: 10px !important;
}
.event-title.field {
  margin-bottom: 0;
}
.event-title.field input {
  border-left: none;
  border-top: none;
  border-right: none;
  border-radius: 0;
  font-weight: bold !important;
  font-size: 32px !important;
  margin-bottom: 10px !important;
}
.event-subtitle {
  font-weight: 500 !important;
  font-size: 24px !important;
}
.event-date {
  font-weight: bold !important;
  font-size: 18px !important;
}
.event-location {
  font-weight: 500 !important;
  font-size: 14px !important;
}
.oauth-square {
  background-color: #e7e7e7;
  border-radius: 4px;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}
.oauth-square:hover {
  background-color: #9b9b9b;
  cursor: pointer;
}
.oauth-square:hover > * {
  opacity: 0.6;
}
.support-dialog {
  opacity: 0;
  pointer-events: none;
  -webkit-transition: opacity ease-in-out 0.3s;
  -moz-transition: opacity ease-in-out 0.3s;
  -ms-transition: opacity ease-in-out 0.3s;
  -o-transition: opacity ease-in-out 0.3s;
}
.support-dialog > .well {
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
  width: 100vw;
  max-height: calc((100vh) - 20px);
  max-width: 748px;
  z-index: 1031;
  overflow-y: auto;
}
.support-dialog.active {
  opacity: 1;
  pointer-events: all;
}
.support-dialog.active > .well {
  transform: translate(-50%, -50%);
}
.chat-image {
  display: inline-block;
  width: 40px;
  margin-right: 10px;
  border-radius: 40px;
  overflow: hidden;
  float: left;
  min-height: 1px;
  position: relative;
}
.chat-image .request-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: none;
}
.chat-image .request-chat {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  color: #fffefd;
  background-color: rgba(32, 191, 85, 0.9);
  opacity: 0;
  pointer-events: none;
  -webkit-transition: opacity ease-in-out 0.3s;
  -moz-transition: opacity ease-in-out 0.3s;
  -ms-transition: opacity ease-in-out 0.3s;
  -o-transition: opacity ease-in-out 0.3s;
  border-radius: 50px;
  cursor: pointer;
}
.chat-image .request-chat > i {
  margin: 12px 11px;
}
.chat-image:hover .request-chat {
  opacity: 1;
  pointer-events: all;
}
.chat-block-wrapper {
  border-left: solid 0px;
  padding-left: 0;
  transition: max-height ease-in-out 0.3s;
  max-height: 10000px;
}
.chat-block-wrapper.collapsed {
  max-height: 0px;
  overflow: hidden;
  transition: all ease-in-out 0.3s;
  padding-bottom: 0 !important;
}
.chat-block {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 50px);
}
.chat-block i {
  font-size: 10px;
}
.chat-block .chat-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.chat-block .chat-actions-container {
  width: 48%;
}
.chat-block .chat-actions-container .chat-actions-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  overflow-y: hidden;
  max-height: 25px;
  /* approximate max height */
  transition-property: all;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}
.chat-block .chat-actions-container .hide-actions {
  max-height: 0;
}
.chat-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: left;
}
.chat-container .moderator {
  padding-bottom: 60px;
}
.chat-container .moderator input {
  margin-top: 60px;
}
.chat-container.light label {
  color: #25272b;
}
.chat-container.light .chat-image {
  color: #ffffff;
}
.chat-container.hidden {
  width: 0;
  height: 0;
  overflow: hidden;
  pointer-events: none;
  opacity: 0;
}
.chat-container.has-chat-picker {
  top: 60px;
}
.chat-toggle {
  display: block;
  text-align: left;
  background-color: #4a525a;
  color: #fffefd;
  padding: 10px !important;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}
.chat-toggle.active {
  background-color: #e7e7e7;
  color: #24272b;
}
.chat-toggle span {
  background-color: #004bed;
  padding: 3px 10px;
  border-radius: 12px;
  color: #fffefd;
  margin-left: 10px;
  font-size: 10px;
  vertical-align: middle;
}
.status-indicator {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #e7e7e7;
  position: absolute;
  top: 16px;
  right: 12px;
  border-radius: 10px;
}
.status-indicator.active {
  background-color: #3ddc97;
}
.container-sm {
  width: 100%;
  max-width: 500px;
  margin: auto;
}
.list-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.well {
  width: 100%;
}
.btn-row {
  padding: 15px !important;
  margin-top: 10px;
  background-color: #4a525a;
  border-radius: 4px;
  position: relative;
  display: block;
  cursor: pointer;
}
.btn-row h2 {
  font-size: 16px;
  line-height: 22px;
  margin: 0;
  font-weight: 400;
}
.btn-row p {
  font-size: 12px;
  line-height: 22px;
  margin: 0;
  font-weight: 400;
}
.btn-row > i {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translate(0, -50%);
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}
.btn-row:hover i {
  right: 10px;
}
.request-button {
  padding: 15px !important;
  margin-top: 10px;
  min-height: 52px;
  background-color: #4a525a;
  border-radius: 4px;
  position: relative;
  display: block;
  cursor: pointer;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}
.request-button .chat-image {
  background-color: #f44c3f;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -8px;
  margin-left: -7px;
  color: #fffefd;
}
.request-button .chat-image.blue {
  background-color: #004bed;
}
.request-button .count-bubble {
  display: inline-block;
  padding: 0px 6px;
  font-size: 10px;
  position: absolute;
  top: 6px;
  left: 35px;
  border-radius: 12px;
  background-color: #fffefd;
  color: #24272b;
}
.request-button h2 {
  font-size: 16px;
  line-height: 22px;
  margin: 0;
  font-weight: 400;
}
.request-button p {
  font-size: 11px;
  line-height: 22px;
  margin: 0;
  font-weight: 400;
}
.request-button:hover {
  background-color: #4a525a;
}
.request-button > i {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translate(0, -50%);
}
.request-button.active {
  background-color: #fffefd;
  color: #24272b;
}
.request-button.active .count-bubble {
  display: none;
}
.loader-screen {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.75);
  opacity: 0;
  pointer-events: none;
  -webkit-transition: opacity ease-in-out 0.3s;
  -moz-transition: opacity ease-in-out 0.3s;
  -ms-transition: opacity ease-in-out 0.3s;
  -o-transition: opacity ease-in-out 0.3s;
}
.loader-screen.active {
  opacity: 1;
  pointer-events: all;
}
.loader-screen i {
  color: #fffefd;
}
.loader-screen h2 {
  color: #24272b;
  font-weight: 200;
}
#notes-container {
  height: 100%;
}
#notes-container .notes-inner-container {
  height: inherit;
}
#notes-container .notes-inner-container form {
  height: 100%;
}
#notes-container .notes-inner-container form .notes-wrapper {
  height: 100% !important;
}
#notes-container .notes-inner-container form .notes-wrapper .field {
  margin: 0;
  height: 100% !important;
}
#notes-container .notes-inner-container form .notes-wrapper .field label {
  display: none;
}
#notes-container .notes-inner-container form .notes-wrapper .field textarea {
  height: inherit;
}
#notes-container .notes-inner-container form .notes-wrapper .field .tox-tinymce {
  height: 100% !important;
  border: none;
}
#notes-container .notes-inner-container form .notes-wrapper .field .tox-tinymce .tox-editor-container {
  height: 100% !important;
}
#notes-container .notes-inner-container form .notes-wrapper .field .tox-toolbar__primary {
  background: none !important;
}
#notes-container .notes-inner-container form .notes-wrapper .field .tox-toolbar__primary:after {
  content: "";
  display: block;
  margin: 0 auto;
  width: 90%;
  padding-top: 5px;
  border-bottom: 1px solid #e7e7e7;
}
.provider-livingAsOneSingle.content-type-live a #la1-seekBar {
  display: none;
}
.unmute-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 500;
  padding: 2px 10px;
  background: white;
  border-radius: 5.18px;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  -webkit-appearance: none;
  z-index: 998;
  -webkit-transition: background-color ease-in-out 0.3s;
  -moz-transition: background-color ease-in-out 0.3s;
  -ms-transition: background-color ease-in-out 0.3s;
  -o-transition: background-color ease-in-out 0.3s;
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.23);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.23);
}
.unmute-button:hover {
  background-color: #e7e7e7;
}
.progress {
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  background-color: #393e45;
  height: 0;
  margin-top: 0;
}
.progress.active {
  height: 8px;
  margin-top: 8px;
}
.progress .progress-bar {
  background-color: #004bed;
}
.reaction-icons {
  display: inline-block;
  text-align: right;
  width: 100%;
  position: relative;
  z-index: 900;
}
.reaction-icons .reaction-icon {
  width: 100%;
  max-width: 30px;
  display: inline-block;
  cursor: pointer;
  -webkit-transition: all cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.3s;
  -moz-transition: all cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.3s;
  -ms-transition: all cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.3s;
  -o-transition: all cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.3s;
  transform: scale(1);
}
.reaction-icons .reaction-icon img {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  max-height: 28px;
}
.reaction-icons .reaction-icon:hover {
  transform: scale(1.1);
}
.reaction-flow-icons {
  position: relative;
}
.reaction-flow-icons .reaction-icon {
  position: absolute;
  width: 30px;
  display: inline-block;
  pointer-events: none;
}
.reaction-pickers .field {
  display: inline-block;
  width: 64px;
  margin-left: 4px;
}
.reaction-pickers .field:first-child {
  margin-left: 0;
}
.reaction-pickers .reaction-picker {
  background-color: none;
  max-width: 64px;
  width: 100%;
  text-align: center;
  border-radius: 4px;
  padding: 4px;
  display: inline-block;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  cursor: pointer;
}
.reaction-pickers .reaction-picker:hover {
  background-color: #393e45;
}
.reaction-pickers .reaction-picker.active {
  background-color: #9b9b9b;
}
.reaction-pickers .reaction-picker input {
  opacity: 0;
  width: 0;
  height: 0 !important;
  pointer-events: none;
  margin: 0;
  padding: 0;
}
.reaction-pickers .reaction-picker image {
  width: 100%;
  margin-left: 4px;
}
.reaction-counts {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: space-around;
  height: 100%;
  text-align: center;
}
.reaction-counts .reaction-count img {
  width: 30px;
  display: inline-block;
  vertical-align: middle;
}
.reaction-counts .reaction-count span {
  font-size: 12px;
  padding: 7px;
  vertical-align: middle;
}
.reaction-counts .reaction-count .fade-enter {
  opacity: 0.01;
}
.reaction-counts .reaction-count .fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.reaction-counts .reaction-count .fade-leave {
  display: none;
}
.reaction-counts .reaction-count .fade-leave.fade-leave-active {
  display: none;
}
.reaction-counts.large {
  justify-content: space-between;
  flex-direction: row;
}
.reaction-counts.large .reaction-count img {
  width: 50px;
  display: inline-block;
  vertical-align: middle;
}
.reaction-counts.large .reaction-count span {
  font-size: 18px;
}
.host-video-wrapper {
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width : 992px) {
  .host-video-wrapper:not(.active) section {
    display: none;
  }
}
.host-video-wrapper section {
  flex: 1;
  position: relative;
}
.host-video-wrapper section .host-video-inner-wrapper {
  height: calc(100vh - 142px);
  display: flex;
  flex-direction: column;
}
.host-video-wrapper section .host-video-inner-wrapper .video-wrapper {
  width: 100%;
  overflow: unset !important;
}
.host-video-wrapper section .host-video-inner-wrapper .well-content {
  flex: 1;
  min-height: 20px;
}
.host-video-columns {
  display: flex;
  flex-direction: row;
  border-bottom: solid 2px #2d2f32;
  position: relative;
}
.host-video-columns .well-header {
  border: none;
  width: 120px;
  height: 100%;
  padding: 10px;
}
@media only screen and (max-width : 992px) {
  .host-sidebar-wrapper:not(.active) {
    display: none;
  }
}
.host-sidebar-wrapper .host-sidebar {
  height: 100%;
  border-right: solid 2px #2d2f32;
  width: 94px;
  padding: 20px;
  display: inline-block;
  vertical-align: top;
}
@media only screen and (max-width : 992px) {
  .host-sidebar-wrapper .host-sidebar {
    display: none;
  }
}
.host-sidebar-wrapper .host-sidebar .btn {
  background-color: rgba(0, 0, 0, 0);
  padding: 10px;
  height: 54px;
  display: flex;
  justify-content: center;
  color: #fffefd;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}
.host-sidebar-wrapper .host-sidebar .btn:hover {
  background-color: #2d2f32;
}
.host-sidebar-wrapper .host-sidebar .btn.active {
  background-color: #004bed !important;
}
.host-sidebar-wrapper .host-sidebar .btn i {
  align-self: center;
  color: #fffefd;
}
.host-panel {
  height: 100%;
  width: 0;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
}
.host-panel.show {
  width: 100%;
}
.host-panel .border-right {
  border-right: solid 2px #2d2f32 !important;
}
.host-panel .row {
  height: 100%;
}
.host-panel .row h3 {
  margin-top: 20px;
}
.host-panel .row .chat-wrapper {
  height: calc(100% - 22px);
}
.host-panel .row .chat-wrapper .chat-container {
  margin-top: 10px;
}
.host-panel .chats-column {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 10px;
}
.host-panel .chats-column .chats-host {
  flex-basis: 103px;
}
@media only screen and (max-width : 992px) {
  .host-panel .chats-column .chat-manager-heading {
    margin-bottom: 10px;
    cursor: pointer;
  }
  .host-panel .chats-column .chat-manager-heading .count-bubble {
    display: inline-block;
    background-color: #fffefd;
    color: #24272b;
    font-size: 12px;
    vertical-align: middle;
    border-radius: 20px;
    padding: 0px 8px;
    margin-top: -4px;
    margin-left: 5px;
  }
  .host-panel .chats-column .chat-manager-heading i {
    float: right;
    margin-left: 10px;
  }
}
.host-panel .chats-column .chats-mine {
  flex: 2;
  overflow: auto;
}
@media only screen and (max-width : 992px) {
  .host-panel .chats-column .chats-mine {
    -webkit-transition: all ease-in-out 0.3s;
    -moz-transition: all ease-in-out 0.3s;
    -ms-transition: all ease-in-out 0.3s;
    -o-transition: all ease-in-out 0.3s;
  }
  .host-panel .chats-column .chats-mine:not(.active) {
    flex: 0;
  }
  .host-panel .chats-column .chats-mine .request-button:first-child {
    margin-top: 0;
  }
}
.host-panel .chats-column .chats-requests {
  flex: 1;
  overflow: auto;
}
@media only screen and (max-width : 992px) {
  .host-panel .chats-column .chats-requests {
    -webkit-transition: all ease-in-out 0.3s;
    -moz-transition: all ease-in-out 0.3s;
    -ms-transition: all ease-in-out 0.3s;
    -o-transition: all ease-in-out 0.3s;
  }
  .host-panel .chats-column .chats-requests:not(.active) {
    flex: 0;
  }
  .host-panel .chats-column .chats-requests .request-button:first-child {
    margin-top: 0;
  }
}
.end-chat {
  background-color: #e71d36;
  color: #fffefd !important;
  text-align: center;
  display: inline-block !important;
  margin-bottom: 4px;
  border-radius: 4px;
}
.host-switch-panel .well-content {
  height: 100%;
}
@media only screen and (max-width : 992px) {
  .host-switch-panel .well-content {
    width: 100%;
    overflow: hidden;
  }
  .host-switch-panel .well-content .host-panel {
    width: 200%;
    position: absolute;
    left: 0;
    -webkit-transition: left ease-in-out 0.4s;
    -moz-transition: left ease-in-out 0.4s;
    -ms-transition: left ease-in-out 0.4s;
    -o-transition: left ease-in-out 0.4s;
  }
  .host-switch-panel .well-content .host-panel.active {
    left: -100%;
  }
  .host-switch-panel .well-content .host-panel .border-right {
    border: none !important;
  }
}
@media only screen and (max-width : 992px) {
  .hide-mobile-border {
    border: none !important;
  }
}
.banned-users {
  position: absolute;
  top: -10px;
  left: -10px;
  width: calc(100% + 20px);
  text-align: right;
  z-index: 1001;
}
.banned-users .btn {
  background-color: #fffefd !important;
  color: #24272b !important;
  z-index: 1001;
  opacity: 0;
  pointer-events: none;
  padding: 0;
  border-radius: 25px;
  -webkit-transition: opacity ease-in-out 0.3s;
  -moz-transition: opacity ease-in-out 0.3s;
  -ms-transition: opacity ease-in-out 0.3s;
  -o-transition: opacity ease-in-out 0.3s;
}
.banned-users .well {
  background-color: #fffefd;
  z-index: 1001;
}
.banned-users .well > div {
  color: #24272b;
}
.banned-users.has-banned-users .btn {
  opacity: 1;
  pointer-events: all;
}
@media only screen and (max-width : 992px) {
  .current-live-badge {
    display: none;
  }
}
@media only screen and (max-width : 600px) {
  .current-live-badge {
    display: none;
  }
}
.host-live-badge {
  position: absolute;
  right: 30px;
}
@media only screen and (max-width : 992px) {
  .host-live-badge {
    position: fixed;
    top: 20px;
    right: 60px;
    z-index: 1000;
  }
}
.mute-tooltip.show {
  opacity: 1;
}
.mute-tooltip div {
  color: #24272b;
  padding: 5px;
}
.mute-tooltip .tooltip-inner {
  background-color: #fffefd;
}
.mute-tooltip .arrow {
  display: none;
}
.chat-login {
  padding-right: 7.5px;
}
.zopim {
  z-index: 99 !important;
}
.well-subtitle {
  margin-top: 20px;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  letter-spacing: 1.3px;
}
.pill-row {
  display: flex;
  flex-direction: row;
  margin: 0 -3px;
  justify-items: stretch;
  align-items: stretch;
}
@media only screen and (max-width : 768px) {
  .pill-row {
    display: block;
  }
}
.pill-row .pill {
  display: inline-block;
  background-color: #4a525a;
  margin: 3px;
  padding: 10px;
  border-radius: 4px;
  width: 25%;
}
@media only screen and (max-width : 768px) {
  .pill-row .pill {
    width: 100%;
  }
}
.pill-row .pill.pill-4 {
  width: 33.33333333%;
}
@media only screen and (max-width : 600px) {
  .pill-row .pill.pill-4 {
    width: 100%;
  }
}
.pill-row .pill.pill-6 {
  width: 50%;
}
.pill-row .pill.pill-12 {
  width: 100%;
}
.pill-row .pill .fade-enter {
  opacity: 0.01;
}
.pill-row .pill .fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.pill-row .pill .fade-leave {
  display: none;
}
.pill-row .pill .fade-leave.fade-leave-active {
  display: none;
}
.pill-row .pill h5 {
  font-size: 12px;
  line-height: 14px;
  color: #e7e7e7;
  font-weight: 300;
  text-transform: uppercase;
}
.pill-row .pill h4 {
  margin-top: 15px;
  font-size: 18px;
  line-height: 21px;
  color: #e7e7e7;
  text-transform: uppercase;
}
.pill-row .pill h3 {
  margin-top: 15px;
  font-size: 24px;
  line-height: 29px;
  color: #e7e7e7;
  text-transform: uppercase;
}
#dropdown-basic-button {
  display: block;
  width: 100%;
}
.bfo-table {
  width: 100%;
  overflow-x: auto;
}
.bfo-table th,
.bfo-table td {
  padding: 10px;
}
.bfo-table tr:nth-child(2n) {
  background-color: #393e45;
}
.standalone-iframe {
  height: 100vh;
  width: 100vw;
  border: none;
  display: block;
}
.big-avatar {
  width: 120px;
  height: 120px;
  text-align: center;
  margin: -71px auto 20px auto;
  border-radius: 120px;
  overflow: hidden;
  border: solid 5px #fffefd;
}
.host-video-inner-wrapper .pinned-chat {
  top: -20px !important;
  left: -24px !important;
  width: calc(100% + 48px) !important;
  border-radius: 0 !important;
  border-bottom: solid 1px;
}
.host-transfer-overlay {
  position: absolute;
  width: calc(100% + 30px);
  height: calc(100% + 40px);
  z-index: 99;
  backdrop-filter: blur(4px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  opacity: 0;
  top: -15px;
  left: -15px;
  padding: 15px;
  -webkit-transition: opacity ease-in-out 0.3s;
  -moz-transition: opacity ease-in-out 0.3s;
  -ms-transition: opacity ease-in-out 0.3s;
  -o-transition: opacity ease-in-out 0.3s;
  pointer-events: none;
}
.host-transfer-overlay .cancel {
  align-self: flex-end;
}
.host-transfer-overlay .cancel i {
  padding: 8px 10px;
  background-color: transparent;
  border-radius: 20px;
  width: 30px;
  height: 30px;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}
.host-transfer-overlay .cancel i:hover {
  cursor: pointer;
  background-color: #4a525a;
}
.host-transfer-overlay .host-transfer-options {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.host-transfer-overlay.active {
  opacity: 1;
  pointer-events: all;
}
#schedule-container {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}
/*!
 * https://github.com/YouCanBookMe/react-datetime
 */
.rdt {
  position: relative;
}
.rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #24272b;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #f9f9f9;
}
.rdtOpen .rdtPicker {
  display: block;
}
.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}
.rdtPicker .rdtTimeToggle {
  text-align: center;
}
.rdtPicker table {
  width: 100%;
  margin: 0;
}
.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px;
}
.rdtPicker td {
  cursor: pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer;
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999;
}
.rdtPicker td.rdtToday {
  position: relative;
}
.rdtPicker td.rdtToday:before {
  content: "";
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428bca;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #428bca;
  color: #24272b;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #24272b;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker td span.rdtOld {
  color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker th {
  border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default;
}
.rdtPicker th.rdtSwitch {
  width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
}
.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}
.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker thead tr:first-child th {
  cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee;
}
.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9;
}
.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}
.rdtPicker button:hover {
  background-color: #eee;
}
.rdtPicker thead button {
  width: 100%;
  height: 100%;
}
td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}
.rdtCounters {
  display: inline-block;
}
.rdtCounters > div {
  float: left;
}
.rdtCounter {
  height: 100px;
}
.rdtCounter {
  width: 40px;
}
.rdtCounterSeparator {
  line-height: 100px;
}
.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}
.rdtCounter .rdtBtn:hover {
  background: #eee;
}
.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}
.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}
.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}
.rdtTime td {
  cursor: default;
}
html,
body {
  background: #15171a;
  width: 100%;
  height: 100%;
  font-family: ff-basic-gothic-pro, sans-serif;
  min-height: -webkit-fill-available;
}
@media only screen and (max-width : 992px) {
  html.lock-scroll,
  body.lock-scroll {
    touch-action: none;
  }
}
@media only screen and (max-width : 992px) and (orientation: portrait) {
  html.lock-scroll,
  body.lock-scroll {
    overflow: hidden;
  }
}
* {
  touch-action: pan-x pan-y;
}
.dashboard {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}
.dashboard .dashboard-container {
  min-height: calc(100vh - 200px);
}
*:focus {
  outline: none !important;
}
.m-top {
  margin-top: 10px !important;
}
.m-right {
  margin-right: 10px !important;
}
.m-bottom {
  margin-bottom: 10px !important;
}
.m-left {
  margin-left: 10px !important;
}
.m-top-sm {
  margin-top: 5px !important;
}
.m-right-sm {
  margin-right: 5px !important;
}
.m-bottom-sm {
  margin-bottom: 5px !important;
}
.m-left-sm {
  margin-left: 5px !important;
}
.m-top-medium {
  margin-top: 20px !important;
}
.m-right-medium {
  margin-right: 20px !important;
}
.m-bottom-medium {
  margin-bottom: 20px !important;
}
.m-left-medium {
  margin-left: 20px !important;
}
.m-top-large {
  margin-top: 40px !important;
}
.m-right-large {
  margin-right: 40px !important;
}
.m-bottom-large {
  margin-bottom: 40px !important;
}
.m-left-large {
  margin-left: 40px !important;
}
.m-0 {
  margin: 0 !important;
}
.p {
  padding: 10px;
}
.p-0 {
  padding: 0 !important;
}
@media only screen and (max-width : 575px) {
  .p-0-sm {
    padding: 0 !important;
  }
}
.p-top {
  padding-top: 10px !important;
}
.p-right {
  padding-right: 10px !important;
}
.p-bottom {
  padding-bottom: 10px !important;
}
.p-left {
  padding-left: 10px !important;
}
.f-right {
  float: right;
}
.padded {
  padding: 10px;
}
.padded-medium {
  padding: 20px;
}
.ta-left {
  text-align: left !important;
}
.ta-center {
  text-align: center !important;
}
.ta-right {
  text-align: right !important;
}
.example-enter {
  opacity: 0.01;
}
.container.wizard {
  max-width: 768px;
}
.bold {
  font-weight: 700 !important;
}
.blur-bg {
  backdrop-filter: blur(4px);
}
.alt {
  color: #004bed;
  font-weight: 300;
  text-transform: uppercase;
}
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  flex-flow: row wrap;
}
.row [class*="col-"].col-eq-height {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  flex-grow: 1;
}
.row [class*="col-"].col-eq-height > * {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  flex-grow: 1;
}
.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}
.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}
.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}
.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}
.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}
.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}
.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}
.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}
.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}
.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}
.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}
.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}
.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}
.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}
.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}
.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}
.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
i.fa.giant,
i.fas.giant,
i.fal.giant,
i.fab.giant,
i.fad.giant {
  font-size: 70px;
}
.absolute-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
  width: 100%;
  max-width: 800px;
}
.padded {
  padding: 10px;
}
a {
  color: #004bed;
}
a:hover {
  color: #548aff;
}
a.white-link {
  color: #fffefd;
  text-decoration: underline;
}
.splash a {
  color: #ffffff;
  transition: all 0.25s;
}
.splash a:hover {
  color: #ffffff;
  opacity: 0.5;
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: ff-basic-gothic-pro, sans-serif;
}
h2 {
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 0px;
}
h3 {
  font-size: 14px;
  font-weight: 800;
  letter-spacing: 0px;
}
h4 {
  font-size: 16px;
  font-weight: 800;
  letter-spacing: 0px;
  font-weight: 300;
}
h6 {
  font-size: 12px;
  font-weight: 800;
}
.cursor-pointer {
  cursor: pointer;
}
.nav-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  z-index: 999;
}
.nav-container .nav-live-badge {
  display: none;
}
@media only screen and (max-width : 992px) {
  .nav-container {
    height: 70px;
  }
  .nav-container .nav-live-badge {
    display: block;
    position: fixed;
    right: 50px;
  }
}
@media only screen and (max-width : 600px) {
  .nav-container {
    height: 40px;
  }
  .nav-container .nav-live-badge {
    position: fixed;
  }
}
.nav-container .logo {
  padding-top: 15px;
  padding-bottom: 15px;
  min-width: 25%;
}
.nav-container .logo img {
  max-height: 35px;
  max-width: 30vw;
}
.nav-container .menu,
.mobile-menu {
  display: flex;
  flex-direction: row;
}
.nav-container .menu {
  padding-left: 20px;
  padding-right: 20px;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.25s;
}
.nav-container .menu a {
  margin-right: 40px;
}
.nav-container .menu a:last-child {
  margin-right: 0px;
}
.mobile-menu div,
.mobile-menu a {
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.25s;
}
.nav-container .menu,
.mobile-menu div:hover {
  cursor: pointer;
}
.mobile-menu {
  display: none;
}
#Drawer {
  position: fixed;
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
  z-index: 999 !important;
  overflow-x: hidden;
  transition: 0.5s;
  background-color: #fff;
}
#Drawer .drawer-header {
  padding: 1em;
  padding-top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
#Drawer .drawer-menu {
  padding: 0.4em 1em;
}
#Drawer .drawer-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1em;
}
#Drawer .drawer-footer .drawer-footer-inner-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
#Drawer .drawer-footer .drawer-footer-inner-wrapper h3 {
  margin: 0 10px 0 0;
  font-size: 0.7em;
}
#Drawer .drawer-footer .drawer-footer-inner-wrapper img {
  max-width: 100px;
}
.drawer-show {
  width: 100% !important;
}
.drawer-menu-icon {
  font-size: 25px;
  font-weight: bold;
}
.header-image-wrapper {
  margin-bottom: 10px;
  width: 100%;
  min-height: 170px;
  height: auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-image-wrapper .header-image {
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.header-image-wrapper .header-image-mobile {
  display: none;
}
.content-wrapper {
  width: calc(100vh / 9 * 16 + 80px);
  max-width: 100%;
  height: 100vh;
  margin: auto;
  padding: 40px;
  padding-top: 115px;
}
.content-wrapper.with-header-image {
  width: calc(100vh / 9 * 16 - 40px);
}
.content-wrapper .empty-channel {
  margin-top: 40px;
}
@media only screen and (max-width : 992px) {
  .content-wrapper .empty-channel {
    margin-top: 0;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
  }
}
.card-info-container h5 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.card-list {
  display: flex;
  flex-wrap: wrap;
  flex-flow: row wrap;
}
.card-list .card {
  width: calc(33.33% - 6.66px);
  margin-right: 10px;
  margin-bottom: 10px;
  transition: all 0.25s;
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
  text-decoration: none;
  border: none;
  border-radius: 0px !important;
}
.card-list .card .card-info {
  padding: 15px;
}
.card-list .card .card-info h1,
.card-list .card .card-info h2,
.card-list .card .card-info h3,
.card-list .card .card-info h4,
.card-list .card .card-info h5,
.card-list .card .card-info h6,
.card-list .card .card-info p,
.card-list .card .card-info span {
  font-weight: 100;
}
.card-list .card .card-info .description {
  height: 20px;
  overflow: hidden;
}
.card-list .card .card-info .description h1,
.card-list .card .card-info .description h2,
.card-list .card .card-info .description h3,
.card-list .card .card-info .description h4,
.card-list .card .card-info .description h5,
.card-list .card .card-info .description h6,
.card-list .card .card-info .description p,
.card-list .card .card-info .description span {
  font-size: 16px;
  font-weight: 100;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.card-list .card h2 {
  font-weight: 100;
  margin-bottom: 0;
}
.card-list .card p {
  font-weight: 100;
  margin-bottom: 0;
}
.card-list .card:nth-child(3n) {
  margin-right: 0;
}
.card-list .card:hover {
  transform: scale(1.02);
  transition: all 0.25s;
}
.card-list .card .card-image {
  width: 100%;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio */
  background-size: cover;
  background-position: center center;
}
.card-list .card img {
  width: 100%;
}
.horizontal-card-list {
  display: block;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  padding: 25px;
  margin: 0 -25px;
}
.horizontal-card-list .card {
  width: 370px;
  display: inline-block;
  *display: inline;
  /* For IE7*/
  *zoom: 1;
  /* For IE7*/
  vertical-align: top;
}
.horizontal-card-list .card:nth-child(3n) {
  margin-right: 10px;
}
.card-image-mobile {
  width: 100%;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio */
  background-size: cover;
  background-position: center center;
  background-color: #24272b;
}
.video-container {
  display: flex;
  width: 100%;
  position: relative;
  justify-content: space-between;
}
@media only screen and (max-width : 992px) {
  .video-container:not(.thumbnail) {
    position: fixed;
    right: 0;
  }
}
@media only screen and (max-width : 992px) {
  .video-container.thumbnail {
    margin-top: 70px;
  }
}
@media only screen and (max-width : 600px) {
  .video-container.thumbnail {
    margin-top: 40px;
  }
}
@media only screen and (max-width : 992px) {
  .remove-video-container:not(.thumbnail) {
    position: relative;
    right: 0;
  }
}
.video-container .video {
  overflow: hidden;
  transition: all 0.25s;
}
.video-container .video-panel {
  flex-direction: row;
  width: 396px;
  margin-left: 10px;
  position: relative;
}
.video-container .video-panel .video-panel-content {
  width: calc(100% - 50px);
  height: 100%;
  position: relative;
}
.video-container .video-panel .video-panel-sidebar {
  float: right;
  height: 100%;
  width: 50px;
}
.chat-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.chat-container:after {
  content: "";
  display: block;
  width: 100%;
}
.video-links-desktop {
  display: flex;
  flex-direction: row;
  height: 50px;
}
.video-links-mobile {
  display: none;
}
.header-info-wrapper {
  display: flex;
  flex-direction: row;
  padding: 0.9em;
  position: relative;
  flex-wrap: wrap;
}
.header-info-wrapper h2 {
  font-weight: 300;
}
.header-info-wrapper h3 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.header-info-wrapper .header-info-title {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.social-wrapper i {
  font-size: 20px;
  margin-left: 15px;
}
.social-wrapper a {
  transition: all 0.25s;
}
.social-wrapper > div > div:first-child > a > i {
  margin-left: 0;
}
.on-demand-container {
  margin-top: 25px;
}
.on-demand-container h2 {
  margin-bottom: 10px;
}
.video-card-info-container h2,
.video-card-info-container h4 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.video-card-info-container h2 {
  padding-bottom: 20px;
}
.video-card-info-container .video-card-description-wrapper h4 {
  margin: 0;
}
.video-card-info-container .video-card-description-wrapper .video-description {
  max-width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
}
.react-modal {
  position: absolute;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  width: calc(100% - 80px);
  max-width: 480px;
  height: calc(100% - 80px);
  max-height: 600px;
  z-index: 1001 !important;
}
.react-modal .modal-header-wrapper {
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  right: -30px;
}
.react-modal .modal-header-wrapper .modal-header-title {
  font-size: 22px;
  letter-spacing: 0px;
  margin: 0;
  display: none;
  flex-direction: row;
  align-items: center;
  font-weight: normal;
}
.react-modal .modal-header-wrapper .modal-header-title span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
  width: calc(100vw - 100px);
}
.react-modal .modal-header-wrapper .modal-close-button {
  font-size: 22px;
  transition: all 0.25s;
}
.react-modal .modal-header-wrapper .modal-close-button:hover {
  cursor: pointer;
  opacity: 0.5;
}
.react-modal .modal-content-wrapper {
  display: flex;
  flex: 1;
  color: #24272b;
  letter-spacing: 0px;
  border-radius: 5px;
  overflow-y: scroll;
}
.react-modal .modal-content-wrapper h1,
.react-modal .modal-content-wrapper h2,
.react-modal .modal-content-wrapper h3,
.react-modal .modal-content-wrapper h4,
.react-modal .modal-content-wrapper h5 {
  font-weight: 500;
  letter-spacing: 0px;
}
.react-modal .modal-content-wrapper p {
  font-weight: 300;
}
.react-modal .modal-content-wrapper a {
  color: #24272b;
  text-decoration: none;
  font-weight: 600;
}
.react-modal .modal-content-wrapper a:hover {
  text-decoration: underline;
  text-decoration-color: #24272b;
}
.react-modal .modal-content-wrapper > div {
  width: 100%;
}
.react-modal.react-modal-share {
  height: initial;
  max-height: 200px;
}
.react-modal.react-modal-share .modal-content-wrapper {
  overflow: hidden;
  flex-direction: column;
  padding: 24px;
}
@media only screen and (max-width : 768px) {
  .react-modal.react-modal-share .modal-content-wrapper {
    justify-content: center;
    padding: 40px;
    margin-bottom: 73px;
  }
}
.react-modal.react-modal-share .share-list {
  display: flex;
  padding-bottom: 10px;
  gap: 10px;
}
@media only screen and (max-width : 768px) {
  .react-modal.react-modal-share .share-list {
    justify-content: center;
  }
}
.react-modal.react-modal-share .share-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3px;
}
.react-modal.react-modal-share .share-item p {
  margin: 0;
  font-size: 10px;
}
.react-modal.react-modal-share .share-item:hover {
  color: #838c98;
  cursor: pointer;
}
.react-modal.react-modal-share .share-title {
  padding-bottom: 10px;
}
@media only screen and (max-width : 768px) {
  .react-modal.react-modal-share .share-title {
    text-align: center;
  }
}
.react-modal-overlay {
  background-color: rgba(0, 0, 0, 0.8);
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1001 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.default-modal .modal-content {
  border-radius: 12px;
  color: #24272b;
}
.default-modal .modal-content .modal-header {
  border-bottom: none;
}
.default-modal .modal-content .modal-footer {
  border-top: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
}
.default-modal .modal-content .modal-footer .modal-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 50%;
  border-radius: 5px;
  color: #fffefd;
  padding: 7px;
}
.default-modal .modal-content .modal-footer .accept-button {
  background-color: #004bed;
}
.default-modal .modal-content .modal-footer .accept-button:hover {
  background-color: #0033a1;
}
.default-modal .modal-content .modal-footer .confirm-button {
  background-color: #e71d36;
}
.default-modal .modal-content .modal-footer .confirm-button:hover {
  background-color: #a61224;
}
.default-modal .modal-content .modal-footer .cancel-button {
  background-color: #9b9b9b;
}
.default-modal .modal-content .modal-footer .cancel-button:hover {
  background-color: #757575;
}
.dark-modal .modal-content {
  background-color: #24272b;
  padding: 5px;
  border-radius: 12px;
}
.dark-modal .modal-content .modal-header {
  display: flex;
  align-items: center;
  border-bottom: none;
  padding-bottom: 0;
  text-transform: uppercase;
  color: #fffefd;
}
.dark-modal .modal-content .modal-header .modal-title {
  font-weight: normal;
  font-size: 32px;
  letter-spacing: 2px;
}
.dark-modal .modal-content .modal-header button {
  font-size: 32px;
}
.dark-modal .modal-content .modal-header button.close {
  float: none;
  color: #fffefd;
  opacity: 1;
}
.dark-modal .modal-content .modal-header button span {
  font-weight: normal;
  text-shadow: none;
}
.dark-modal .modal-content .modal-body .card {
  color: #fffefd;
  background-color: #393e45;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}
.dark-modal .modal-content .modal-body .card:hover {
  background-color: #4a525a;
}
.dark-modal .modal-content .modal-body .card .card-body {
  padding: 0.75rem;
}
.dark-modal .modal-content .modal-body .card .card-body h2 {
  font-size: 24px;
  font-weight: 100;
  letter-spacing: 1px;
  color: #fff;
  margin-bottom: 0;
}
.dark-modal .modal-content .modal-body .card .card-body div.description {
  font-size: 14px;
  color: #dbdee1;
}
.dark-modal .modal-content .modal-body .card:last-of-type {
  margin-bottom: 0;
}
.footer-image-container {
  transition: all 0.25s;
  min-width: 20% !important;
}
.footer-image-container img {
  max-width: 90px;
}
.footer-image-container .footer-logo-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.footer-image-container:hover {
  opacity: 0.5;
  cursor: pointer;
}
.video-panel-sidebar .panel-button {
  display: flex;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
}
.video-panel.disabled {
  display: none;
}
.video-panel #video-list-container {
  display: none;
}
#toggle-videos {
  display: none;
}
.footer-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative !important;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  margin-top: 10em;
}
.footer-container h6 {
  font-weight: 500;
  margin-bottom: 0px;
  margin-right: 10px;
}
.footer-container .footer-attendee-number,
.footer-container .footer-logout {
  font-size: 12px;
}
.footer-container .footer-logout {
  border-radius: 24px;
  padding: 0 5px;
}
.footer-container .footer-logo-wrapper {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
@media only screen and (max-width : 992px) {
  .footer-container {
    position: absolute !important;
  }
}
#footer-navigation {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  max-width: 70%;
}
#footer-navigation a {
  margin: 10px;
  font-size: 12px;
}
#footer-navigation a:hover {
  opacity: 0.5;
  text-decoration: none;
}
#footer-navigation .footer-admin-links {
  display: flex;
  justify-content: center;
}
#footer-navigation .footer-admin-links a {
  margin-top: 10px;
}
.paywall-footer {
  position: relative;
  width: 100%;
  padding: 40px;
}
@media only screen and (max-width : 600px) {
  .paywall-footer {
    display: none;
  }
}
.sticky-container .fixed-sticky {
  z-index: 9999;
  background: linear-gradient(to bottom, transparent 0%, #15171a 100%);
}
.animation-stopper {
  animation: none !important;
  transition: none !important;
}
.video-panel-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.panel-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.channel-popover {
  top: 100px !important;
  border: none;
  -webkit-box-shadow: 3px 3px 5px -4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 3px 3px 5px -4px rgba(0, 0, 0, 0.5);
  box-shadow: 3px 3px 5px -4px rgba(0, 0, 0, 0.5);
}
.channel-popover.host {
  top: 70px !important;
}
.channel-popover .popover-body {
  background-color: #f5f5f5;
  border-radius: 6px;
  width: 160px;
}
.channel-popover .popover-body .border {
  margin-top: 7px;
  margin-bottom: 7px;
}
.channel-popover .popover-body .popover-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px;
}
.channel-popover .popover-body .popover-item .icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 27px;
}
.channel-popover .popover-body .popover-item .icon-wrapper i {
  font-size: 16px;
}
.channel-popover .popover-body .popover-item p,
.channel-popover .popover-body .popover-item button {
  margin: 0;
  font-size: 12px;
}
.channel-popover .popover-body .popover-item:hover {
  color: #838c98;
  cursor: pointer;
}
.channel-popover .arrow {
  display: none;
}
.chat-popover {
  border: none;
  -webkit-box-shadow: 3px 3px 5px -4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 3px 3px 5px -4px rgba(0, 0, 0, 0.5);
  box-shadow: 3px 3px 5px -4px rgba(0, 0, 0, 0.5);
  background-color: transparent !important;
}
.chat-popover.host {
  top: 70px !important;
}
.chat-popover .popover-body {
  background-color: #393e45;
  border-radius: 6px;
  width: 160px;
  color: #fffefd;
  position: relative;
  z-index: 1001;
}
.chat-popover .popover-body .border {
  margin-top: 7px;
  margin-bottom: 7px;
}
.chat-popover .popover-body .popover-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px;
}
.chat-popover .popover-body .popover-item .icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 27px;
  color: #fffefd;
}
.chat-popover .popover-body .popover-item .icon-wrapper i {
  font-size: 16px;
  color: #fffefd;
}
.chat-popover .popover-body .popover-item p {
  margin: 0;
  font-size: 12px;
}
.chat-popover .popover-body .popover-item:hover {
  cursor: pointer;
}
.chat-popover .arrow {
  display: none;
}
.host-chat-header {
  -ms-flex-align: center !important;
  align-items: center !important;
  align-content: center;
}
.host-chat-header .chat-avatar {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  margin-right: 10px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f44c3f;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}
.host-chat-header .chat-avatar > img {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 0;
  left: 0;
}
.host-chat-header h3 {
  margin-top: 0 !important;
  vertical-align: middle;
  flex: 1;
}
.channel-popover-hover {
  top: 0px !important;
}
.channel-popover-hover .popover-body {
  width: 200px;
}
#beacon-container {
  display: block;
}
@media only screen and (max-width : 992px) {
  #beacon-container {
    display: none;
  }
}
body.menuOpen #beacon-container {
  display: block;
}
@media screen and (max-width: 992px) {
  .content-wrapper {
    padding: 0px;
    padding-top: 85px;
    width: 100% !important;
    height: initial;
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  .card-list:not(.horizontal-card-list) {
    padding-left: 10px;
  }
  .card-list .card {
    width: calc(50% - 10px);
    margin-right: 10px;
    cursor: pointer;
    transition: all 0.25s;
  }
  .card-list .card:nth-child(3n) {
    margin-right: 10px;
  }
  .video-container {
    flex-direction: column;
    justify-content: flex-start;
    min-height: calc((var(--vh, 1vh) * 100) - 70px);
  }
  .bumper-video-container {
    width: 100% !important;
    height: 100% !important;
    min-height: 56.25vw !important;
    margin-bottom: 25px;
  }
  .video-container .video {
    width: 100% !important;
  }
  .video-container .video-panel {
    margin-left: 0;
    width: 100% !important;
    flex-direction: column;
    flex: 1;
  }
  .video-container .video-panel #toggle-video-panel {
    display: none;
  }
  .video-container .video-panel #toggle-info,
  .video-container .video-panel #toggle-videos {
    display: flex;
  }
  .video-container .video-panel .video-panel-content {
    position: absolute;
    top: 45px;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: scroll;
    height: auto;
    width: 100%;
  }
  .video-container .video-panel .video-panel-sidebar {
    float: none;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 50px;
  }
  .video-container .video-panel .video-panel-sidebar .panel-button-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-start;
  }
  .video-panel.disabled {
    display: block;
  }
  .video-panel #video-list-container {
    display: block;
  }
  .header-image-wrapper {
    display: none;
  }
  .social-wrapper {
    display: none;
  }
  .header-info-wrapper .header-info-description {
    display: none;
  }
  .video-links-desktop {
    display: none;
  }
  .video-links-mobile {
    display: block;
  }
  .nav-container .menu {
    display: none;
  }
  .mobile-menu {
    display: block;
  }
  .on-demand-container {
    margin-top: 0;
  }
  .on-demand-container:not(.show) {
    display: none;
  }
  .footer-container {
    display: none !important;
  }
  .drawer-footer.footer-container {
    display: flex !important;
    flex-direction: column;
    align-items: flex-start !important;
    margin-bottom: 25px;
  }
  .drawer-footer.footer-container .footer-attendee-number {
    margin-bottom: 40px;
    margin-left: 0px;
  }
  .drawer-footer.footer-container #footer-navigation {
    margin-bottom: 15px;
    min-width: 100%;
    display: flex;
    justify-content: flex-start;
  }
  .drawer-footer.footer-container #footer-navigation a {
    margin: 0;
    margin-right: 20px;
  }
}
.dashboard-container .footer-container {
  position: relative !important;
  height: 200px;
}
.dashboard-container .footer-container .footer-image-wrapper {
  width: 30% !important;
}
.dashboard-container .footer-container .footer-logo-wrapper {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.dashboard-container .footer-container #footer-navigation {
  width: 100% !important;
}
.footer-shim {
  min-height: calc(100% - 210px);
}
.headerless {
  padding-top: 40px;
}
@media screen and (max-width: 600px) {
  .content-wrapper {
    height: initial;
    padding-top: 40px;
  }
  .content-wrapper.client-page {
    padding-top: 50px;
  }
  .nav-container .logo img {
    max-height: 26px;
  }
  .chat-container {
    flex: 1;
  }
  .video-container {
    min-height: calc((var(--vh, 1vh) * 100) - 40px);
  }
  .video-container .video-panel {
    flex: 1;
    display: flex;
    height: 300px;
  }
  .video-container .video-panel .video-panel-content {
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: scroll;
    height: auto;
    flex-direction: column;
    flex: 1;
    display: flex;
  }
  .bumper-video-container {
    width: 100% !important;
    height: 100% !important;
    min-height: 56.25vw !important;
    margin-bottom: 25px;
  }
  .card-list .card {
    width: 100%;
    margin-right: 0;
    cursor: pointer;
    transition: all 0.25s;
  }
  .card-list .card:nth-child(3n) {
    margin-right: 0;
  }
  .card-list .card:nth-child(2n) {
    margin-right: 0;
  }
  .horizontal-card-list .card {
    width: 85%;
    margin-right: 10px;
    cursor: pointer;
    transition: all 0.25s;
  }
  .horizontal-card-list .card:nth-child(3n) {
    margin-right: 10px;
  }
  .horizontal-card-list .card:nth-child(2n) {
    margin-right: 10px;
  }
  .card-image {
    display: none;
  }
  .card-image-mobile {
    display: block;
    width: 100%;
    padding-top: 56.25%;
    /* 16:9 Aspect Ratio */
    background-size: cover;
    background-position: center center;
  }
  .header-info-title {
    display: block !important;
    flex: none !important;
    width: 100%;
  }
  .header-info-title h2 {
    font-size: 18px;
    line-height: 1.3;
    letter-spacing: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .nav-container .menu {
    display: none;
  }
  .mobile-menu {
    display: block;
  }
  .react-modal {
    border-radius: 0;
    display: flex;
    flex-direction: column;
    position: fixed;
    min-height: 100%;
    min-width: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1001 !important;
  }
  .react-modal .modal-header-wrapper {
    color: #24272b;
    position: relative;
    top: 0;
    right: 0;
    padding: 20px;
  }
  .react-modal .modal-header-wrapper .modal-header-title {
    display: flex;
  }
  .video-panel-sidebar {
    height: 32px !important;
  }
  .video-panel-sidebar .panel-button {
    width: 32px;
    height: 32px;
  }
  .video-panel-sidebar .panel-button::before {
    right: 5px;
    top: 5px;
  }
  .video-panel-sidebar .panel-button i {
    font-size: 18px !important;
  }
}
.panel-button {
  position: relative;
}
.panel-button::before {
  content: "";
  display: inline-block;
  background-color: #f44c3f;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  position: absolute;
  top: 10px;
  right: 10px;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: opacity ease-in-out 0.2s;
  -moz-transition: opacity ease-in-out 0.2s;
  -ms-transition: opacity ease-in-out 0.2s;
  -o-transition: opacity ease-in-out 0.2s;
  transition-delay: 0s;
}
.panel-button.has-activity::before {
  opacity: 1;
  transition-delay: 0.2s;
}
.access-keys .key {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #4a525a;
}
.text-copy {
  background-color: #f2f3f5;
  margin: 0;
  position: relative;
  border-radius: 4px;
  width: 100%;
}
.text-copy .text-copy-text {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
  padding-right: 50px;
  color: #24272b;
}
.text-copy .text-copy-text > div {
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-copy .btn {
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  border: none;
}
.text-copy .btn i {
  color: #24272b;
}
.text-copy .btn:hover {
  background-color: transparent;
  border: none;
}
.text-copy.code-copy {
  background-color: #4a525a;
}
.text-copy.code-copy .text-copy-text {
  padding: 0;
}
.text-copy.code-copy code {
  padding-right: 60px;
}
.text-copy.code-copy .btn {
  background-color: #4a525a;
  color: #ffffff;
  top: 0;
  right: 0;
  padding: 12px 10px;
}
.text-copy .block {
  display: block;
}
.text-copy .hidden {
  display: none !important;
  visibility: hidden !important;
}
@media screen and (max-height: 600px), screen and (orientation: landscape) and (max-height: 600px) {
  .content-wrapper {
    height: initial;
  }
  .content-wrapper.client-page {
    padding-top: 85px;
  }
  .video-container {
    position: relative !important;
  }
  .video-container .video-panel .video-panel-content {
    position: relative;
    height: 300px;
    top: 0;
    margin-bottom: 0;
    flex-direction: column;
    flex: 1;
    display: flex;
  }
  .react-modal {
    border-radius: 0;
    display: flex;
    flex-direction: column;
    position: fixed;
    min-height: 100%;
    min-width: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1001 !important;
  }
  .react-modal .modal-header-wrapper {
    color: #24272b;
    position: relative;
    top: 0;
    right: 0;
    padding: 20px;
  }
  .react-modal .modal-header-wrapper .modal-header-title {
    display: flex;
  }
}
/*# sourceMappingURL=main.css.map */